import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import SEO from '../components/seo'
import LocalizedLink from '../components/LocalizedLink'
import useTranslations from '../components/useTranslations'
import * as S from '../components/ProductsList/styled'
import DocumentDownload from "../components/LandingPage/documentDownload"
// import Search from "../components/Search"

const ProductsList = ({ data, pageContext: { locale } }) => {
  const { retmonTitle, retmonDescription, lang, navbar } = useTranslations()

  const ProductsListTR = data.TR.edges
  const ProductsListEN = data.EN.edges

  return (
    <div className="homepage">
      <SEO
        title={`${navbar.products} | ${retmonTitle}`}
        description={retmonDescription}
        lang={lang} />
      <S.Container>
        <S.RowStart>
          <S.Title>{navbar.products}</S.Title>
          {/* <div className='search-section'>
            <Search searchIndex={data.siteSearchIndex.index} />
          </div> */}
        </S.RowStart>
        <S.RowStart
          className='double-grid-list'
          justify='space-between'>
          {locale === 'en'
            ? ProductsListEN.map(
              ({
                node: {
                  frontmatter: { sensorImage, terminalImage, title },
                  fields: { slug },
                },
              }) => (<>
                <LocalizedLink to={`/${navbar.productsSub.dataCollectionURL}/`}>
                  <S.BorderedColumn className='grid-item'>
                    <S.ColumnItems>
                      <S.FeaturedImage fluid={terminalImage.childImageSharp.fluid} alt={title} />
                    </S.ColumnItems>
                    <S.ColumnItems height>
                      <S.SubHeader bold>{navbar.productsSub.dataCollection}</S.SubHeader>
                    </S.ColumnItems>
                  </S.BorderedColumn>
                </LocalizedLink>

                <LocalizedLink to={`/${navbar.productsSub.sensorsEquipmentsURL}/`}>
                  <S.BorderedColumn className='grid-item'>
                    <S.ColumnItems>
                      <S.FeaturedImage fluid={sensorImage.childImageSharp.fluid} alt={title} />
                    </S.ColumnItems>
                    <S.ColumnItems height>
                      <S.SubHeader bold>{navbar.productsSub.sensorsEquipments}</S.SubHeader>
                    </S.ColumnItems>
                  </S.BorderedColumn>
                </LocalizedLink>
              </>
                )
            )
            : ProductsListTR.map(
              ({
                node: {
                  frontmatter: { subTitle, title, sensorImage, terminalImage },
                  fields: { slug },
                },
              }) => (<>
                <LocalizedLink to={`/${navbar.productsSub.dataCollectionURL}/`}>
                  <S.BorderedColumn className='grid-item'>
                    <S.ColumnItems>
                      <S.FeaturedImage fluid={terminalImage.childImageSharp.fluid} alt={title} />
                    </S.ColumnItems>
                    <S.ColumnItems height>
                      <S.SubHeader bold>{navbar.productsSub.dataCollection}</S.SubHeader>
                    </S.ColumnItems>
                  </S.BorderedColumn>
                </LocalizedLink>

                <LocalizedLink to={`/${navbar.productsSub.sensorsEquipmentsURL}/`}>
                  <S.BorderedColumn className='grid-item'>
                    <S.ColumnItems>
                      <S.FeaturedImage fluid={sensorImage.childImageSharp.fluid} alt={title} />
                    </S.ColumnItems>
                    <S.ColumnItems height>
                      <S.SubHeader bold>{navbar.productsSub.sensorsEquipments}</S.SubHeader>
                    </S.ColumnItems>
                  </S.BorderedColumn>
                </LocalizedLink>
              </>
                )
            )}
        </S.RowStart>
        <S.DividerForRow />
      </S.Container>
    </div>
  )
}

export const query = graphql`
  query ProductList {
    EN: allMarkdownRemark(filter: {frontmatter: {page: {eq: "products-list"}}, fields: {locale: {eq: "en"}}}, sort: {order: ASC, fields: frontmatter___order}) {
      edges {
        node {
          html
          excerpt(pruneLength: 250)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            subTitle
            title
            sensorImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
           terminalImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
            src
            srcSet
            sizes
                }
              }
            }
          }
        }
      }
    }
    TR: allMarkdownRemark(filter: {frontmatter: {page: {eq: "products-list"}}, fields: {locale: {eq: "tr"}}}, sort: {order: ASC, fields: frontmatter___order}) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 250)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            subTitle
            title
            sensorImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
           terminalImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
  
`

export default ProductsList
