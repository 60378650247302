import React from "react"
import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const Container = styled.div``

export const RowStart = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.justify || ''};
`
export const RowSectionStart = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.justify || ''};
`
export const Column = styled.div`
  width: 100%;
`
export const BorderedColumn = styled.div`
filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.25));
border-radius: 1rem;
background: white;
padding: 1rem;
height: 19rem;
margin-top: 3rem;
margin-bottom: 3rem;
flex-wrap: wrap;
display: flex;
align-items: center;
justify-content: end;
background:white;
transition: all .3s ease;

  &:hover {
  cursor: pointer;
  transition: all 0.3s ease;
  transform: translate3d(0px, -4px, 0px);

}
${media.lessThan('medium')`
height: 20rem;
`}
${media.lessThan('small')`
width: -webkit-fill-available;
height: 20rem;
text-align: center;
`}
`
export const ColumnItems = styled.div`
width: 20rem;
margin: auto;
${media.lessThan('medium')`
margin: auto;
width:16rem;
`}
`
export const ColumnText = styled.div`
width: 20rem
${media.lessThan('medium')`
`}
${media.lessThan('small')`
width:auto;
`}
`

export const Title = styled.h1`
font-size:${props => (props.size || 'var(--h1)')};
font-weight: var(--bold);
margin-bottom: 1rem;
&:after{
  border-left: 1.5em solid var(--red);
  border-left-color: var(--red);
  display: block;
  content: "";
  height: 0.2rem;
  margin-top: 0.2em;
}
`
export const SubHeader = styled.h2`
  font-size: ${props => props.Size || '18px'};
  color: ${props => props.Color || 'black'};
  font-weight: ${props => props.bold ? 'bold' : ''};
  line-height: ${props => props.height ? '21px' : ''};
  text-align: center;

`
export const Text = styled.p`
  font-size: ${props => props.Size || '16px'};
`

export const DividerForRow = styled.div`
  margin-top: ${props => props.Top || '5em'};
  margin-bottom: ${props => props.Bottom || '5em'};
`

export const RetmonCTAButton = styled.button`
  background: ${props => props.Color || 'var(--red)'};
  color: white;
  padding: 9px;
  width: 10rem;
  transition: all 0.3s ease;
  &:hover {
    background: #ec0753;
    transition: all 0.3s ease;
  }
  &:active,
  &:focus {
    background: var(--redGR);
  }
`

export const FeaturedImage = styled(Img)`
  width: auto;
margin:2rem;
  ${media.lessThan('medium')`
  width: auto;
`}
${media.lessThan('small')`
width: auto;
margin: 2rem;
`}
`
